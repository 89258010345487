import React, { Component } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToMarkdown from 'draftjs-to-markdown';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class EditorConvertToMarkdown extends Component {
    state = {
        editorState: EditorState.createEmpty(),
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    render() {
        const { editorState } = this.state;
        return (
            <>
                <div className='editeur'>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={this.onEditorStateChange}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbar={{
                        }}
                    />
                </div>
                <div className='markdown'>
                    <textarea
                        disabled
                        value={editorState && draftToMarkdown(convertToRaw(editorState.getCurrentContent()))}
                    />
                </div>
            </>
        );
    }
}

export default EditorConvertToMarkdown;